import logger from "@/core/logger"
import context from "@/core/context"
import * as extract from "./extract"
import { TaggingData } from "./types"
import { Equals, Expect, Extends } from "@/types"
import settings from "../settings"
import { validate } from "./validation"

/**
 * reading tagging is relatively expensive operation
 * let's make sure we don't do it more than once per js event loop invocation
 * assuming we don't change tagging while nosto runs
 */
let cachedTagging: TaggingData | null = null

function clearCachedTagging() {
  cachedTagging = null
}

export function pageTagging() {
  if (cachedTagging != null) {
    return cachedTagging
  }
  requestAnimationFrame(clearCachedTagging)
  // Some non-obvious details on how `domReady` works with `clearCachedTagging`
  //
  // 1. If cache is used in case when it can be poisoned, initial cache poisoning happens quite early on,
  //    so `clearCachedTagging` ends up being first in `domReady` listeners list
  //    so it is likely that cleanup is performed before other nosto listeners
  // 2. `clearCachedTagging` might be included several times into domReady listeners list, but that's not a big deal
  // 3. After dom is ready domReady calls the listener in place, but `cachedTagging` is initialized after that invocation
  //    so the cached value is preserved
  context.domReady(clearCachedTagging)
  const dynamicPlacementDivIds = Object.keys(settings.placements || {})
  cachedTagging = getTagging(dynamicPlacementDivIds)
  validate(cachedTagging)

  return cachedTagging
}

export function findCustomer() {
  return taggingProviders.customer()
}

export function findOrder() {
  return taggingProviders.order()
}

function getTagging(dynamicPlacementDivIds: string[]) {
  return {
    products: taggingProviders.products(),
    cart: taggingProviders.cart(),
    customer: taggingProviders.customer(),
    order: taggingProviders.order(),
    searchTerms: taggingProviders.searchTerms(),
    categories: taggingProviders.categories(),
    categoryIds: taggingProviders.categoryIds(),
    parentCategoryIds: taggingProviders.parentCategoryIds(),
    tags: taggingProviders.tags(),
    customFields: taggingProviders.customFields(),
    variation: taggingProviders.variation(),
    pluginVersion: taggingProviders.pluginVersion(),
    elements: taggingProviders.elements(dynamicPlacementDivIds),
    restoreLink: taggingProviders.restoreLink(),
    affinitySignals: taggingProviders.affinitySignals(),
    pageType: taggingProviders.pageType(),
    sortOrder: taggingProviders.sortOrder()
  }
}

const taggingProviders = {
  products: extract.findProducts,
  cart: extract.findCart,
  customer: extract.findCustomer,
  order: extract.findOrder,
  searchTerms: extract.findSearchTerms,
  categories: extract.findCurrentCategories,
  categoryIds: extract.findCurrentCategoryIds,
  parentCategoryIds: extract.findCurrentParentCategoryIds,
  tags: extract.findCurrentTags,
  customFields: extract.findCurrentCustomFields,
  variation: extract.findCurrentVariation,
  pluginVersion: extract.findPluginVersions,
  elements: extract.findElements,
  restoreLink: extract.findRestoreLink,
  affinitySignals: extract.findAffinitySignals,
  pageType: extract.findPageType,
  sortOrder: extract.findSortOrder
}

type Options = {
  priority?: boolean
}

const priorityOverrides = new Set<keyof TaggingData>()

type TaggingProviders = typeof taggingProviders
type MaybeProvider<T extends keyof TaggingData> = TaggingProviders[T] | ReturnType<TaggingProviders[T]>

function normalizeProvider<T extends keyof TaggingData>(provider: MaybeProvider<T>): TaggingProviders[T] {
  return (typeof provider === "function" ? provider : () => provider) as TaggingProviders[T]
}

export function setTaggingProvider<T extends keyof TaggingData>(
  name: T,
  provider: MaybeProvider<T>,
  options?: Options
) {
  if (priorityOverrides.has(name)) {
    logger.info(`Provider for ${name} was already set with priority. Ignoring new provider.`)
    return
  }
  logger.info(`Overriding ${name} tagging extractor with custom provider.`)
  taggingProviders[name] = normalizeProvider(provider)
  if (options?.priority) {
    priorityOverrides.add(name)
  }
}

type TaggingReturnType = ReturnType<typeof getTagging>

/** @hidden */
export type tests = [
  Expect<Extends<TaggingData, TaggingReturnType>>,
  Expect<Extends<TaggingReturnType, TaggingData>>,
  Expect<Equals<TaggingData, TaggingReturnType>>
]
